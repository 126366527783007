@use 'mixins';

.btn {
	background: var(--t-accent-border-color);
	text-transform: uppercase;
	cursor: pointer;
	width: auto;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
	background-image: linear-gradient(
		to right,
		#25200e 0%,
		#544416 15%,
		#25200e 30%,
		#25200e 100%
	);
	background-size: 400% 2px;
	background-position-x: 200%;
	animation-play-state: paused;
	@include mixins.shimmer-effect;
	animation-duration: 4s;

	&__inner {
		background-color: black;
		padding: 1rem 3rem;
		width: 100%;
		height: 100%;
	}
	&:hover,
	&:focus,
	&:active {
		animation-play-state: running;
	}
}

.btn-group {
}
