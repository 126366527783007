@use "mixins";

.l-artwork-stats {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin: calc(var(--p-rhythm) * 1.5) 0 calc(var(--p-rhythm) * 3);

	&__stat {
		width: fit-content;

		&-title {
			font-size: var(--fs-body-sm);
			color: var(--t-dark-text-color);
		}

		&-value {
			display: inline;
			font-size: 30px;
			padding-bottom: 2px;
			border-bottom: 1px solid var(--t-accent-color);
			font-weight: 700;
			line-height: 1;
		}

		&:not(:last-child) {
			margin: 0 0 calc(var(--p-rhythm) * 1.5) 0;
		}
	}
}

@include mixins.from-md {
	.l-artwork-stats {
		flex-direction: row;
		gap: var(--p-rhythm);

		&__stat {
			&-value {
				line-height: initial;
			}

			&:not(:last-child) {
				// margin: 0 var(--p-rhythm) 0 0;
				margin: 0;
			}
		}
	}
}
