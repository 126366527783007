@use "mixins";

.l-archive {
	&__title {
		max-width: var(--page-width);
		margin: 0 auto;
	}

	&__form-inner {
		$i: &;

		&--results {
			opacity: 1;
			transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) opacity;
		}

		&.is-loading {
			#{$i}--results {
				opacity: 0;
			}
		}
	}
}

@include mixins.to_md {
	.l-archive {
		.l-finalists-block__grid-finalists {
			grid-template-columns: 1fr;
		}
	}
}
