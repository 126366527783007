@use "constants";
@use "mixins";

@mixin heading-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: constants.$heading-font;
	letter-spacing: 0.05em;
	font-weight: 300;
}

@mixin body-font() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.05em;
	font-family: constants.$body-font;
}

@mixin base-heading() {
	@include heading-font;
	line-height: 1;
	margin: 0;
	font-size: var(--fs-title);
}

@mixin base-medheading() {
	@include body-font;
	font-size: var(--fs-medtitle);
	line-height: 1;
	font-weight: 300;
}

@mixin base-subheading() {
	@include body-font;
	font-size: var(--fs-subtitle);
	line-height: 1;
}

@mixin base-link() {
	text-decoration: underline;
	text-decoration-color: var(--t-accent-border-color);
	text-underline-offset: 6px;
	transition: text-decoration 150ms ease-in-out;

	&:hover,
	&:focus,
	&:active {
		text-decoration-color: var(--t-accent-color);
	}
}

@mixin base-blurred() {
	opacity: constants.$blurred-opacity;
	filter: constants.$blurred-filter;
}
