@use 'mixins';
@use "global/bases";

.accordion {
	border: 1px solid var(--t-accent-border-color);

	&--item {
		$i: &;

		&:not(:first-child) {
			border-top: 1px solid var(--t-accent-border-color);
		}

		&.is-active {
			#{$i}-icon-trigger-plus {
				display: none;
			}

			#{$i}-icon-trigger-minus {
				display: block;
			}

			#{$i}-content {
				border-bottom: none;
			}
		}

		&:not(.is-active) {
			#{$i}-icon-trigger-minus {
				display: none;
			}

			#{$i}-content {
				&-description {
					opacity: 0;
				}
			}
		}

		&-content {
			display: flex;
			overflow: hidden;
			transition: height 0.3s ease-out;
			height: var(--accordion-height);
			flex-direction: column;

			&-title {
				@include bases.base-medheading;
				letter-spacing: 0em;
				transition: font-size cubic-bezier(0.075, 0.82, 0.165, 1) 0.7s;
				text-transform: uppercase;
			}

			&-description {
				opacity: 1;
				margin: var(--p-rhythm-sm) 0 0;
				font-size: 1rem;
				color: var(--t-category-dark-text-color);
				line-height: 1.125;
				max-width: 500px;
				max-height: 100px;
				transition: all 0.3s 0s;
			}

			&.is-closed {
				height: 0;
				padding: 0;
				border: none;
			}
		}

		&-icon {
			align-self: start;
			justify-self: end;
			height: 20px;
			width: 20px;

			svg {
				color: var(--white);
				width: 20px;
				transition: all 150ms ease-in-out;
			}

			&-hover {
				display: none;
				transition: all 0.3s;
				overflow: hidden;
				width: 0;
				height: auto;
				justify-self: start;
			}
		}

		&-trigger {
			display: grid;
			grid-template-columns: auto 40px;
			transition: all 0.3s;
			padding: var(--p-rhythm-sm) calc(var(--p-rhythm));
			appearance: none;
			outline: none !important;
			position: relative;
		}
	}

	@include mixins.to_md {
		&--item {
			$i: &;

			&-icon {
				align-self: center;
			}

			&-trigger.no-subcategories {
				#{$i}-content-description {
					opacity: 1;
				}
			}

			&:not(.is-active) {
				#{$i}-content-description {
					max-height: 0;
					margin: 0;
				}
			}
		}
	}

	@include mixins.from-md {
		$a: &;

		&:not(.is-active) {
			$i: #{$a}--item;

			#{$i}-trigger:hover {
				#{$i}-content-description {
					opacity: 1;
					max-height: initial;
					transition: all 0.3s 0.25s;
				}
			}
		}

		&--item {
			$i: &;

			&.is-active {
				#{$i}-icon-hover {
					svg {
						transform: rotate(180deg);
					}
				}

				#{$i}-content-description {
					opacity: 1;
				}
			}

			&.is-active &-trigger,
			&-trigger:hover {
				cursor: pointer;

				#{$i}-icon-hover {
					width: 100px;
				}
			}

			&-icon {
				display: block;

				svg {
					width: 68px;
					transition: all 0.3s;
				}
			}

			&-trigger {
				max-height: 160px;
				grid-template-columns: min-content auto 100px;
				padding: var(--p-rhythm) 46px;
				align-self: center;

				&-icon {
					width: 60px;
					height: 60px;

					svg {
						width: 60px;
					}
				}

				&.no-subcategories {
					grid-template-columns: auto;
				}
			}

			&-content {
				margin-bottom: 0.1px;
				flex-direction: row;

				&-description {
					margin: 8px 0 0 6.25em;
					max-height: initial;
				}
			}
		}

		// An item in the accordion is active but not this one
		&.is-active {
			.accordion--item {
				.accordion--item-trigger-icon {
					height: 100%;

					> svg {
						display: block;
						width: 40px;
						margin-left: auto;
					}
				}
			}

			.accordion--item:not(.is-active) {
				.accordion--item-hover-icon > svg {
					margin-left: 12px;
				}

				.accordion--item-icon > svg {
					width: 40px;
				}

				.accordion--item-trigger {
					max-height: 90px;
					padding: var(--p-rhythm-sm) 46px;
				}

				.accordion--item-content-title {
					font-size: 30px;
					margin-top: 4px;
				}
			}
		}
	}
}
