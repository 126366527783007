@use "mixins";
@use 'global/bases';

.person-profile {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: grid;
	margin: 0;
	z-index: 50;
	opacity: 1;
	transition: all 0.3s 0.15s;

	&:not(.is-active) {
		opacity: 0;
		pointer-events: none;
		margin-top: 30px;
		filter: blur(10px);
		transition: all 0.15s;
	}

	&-image {
		&__image {
			@include mixins.round-accent-border;
			width: calc(var(--p-rhythm-lg) * 7);
			height: calc(var(--p-rhythm-lg) * 7);
			margin-left: auto;
		}
	}

	&-image,
	&-details {
		&__back {
			cursor: pointer;
			width: fit-content;
			font-size: var(--fs-body-sm);
			display: flex;
			align-items: center;
			gap: var(--p-rhythm-sm);

			& > svg {
				display: block;
				width: 4px;
				height: auto;
				transition: transform 150ms ease-in-out;
				will-change: transform;
			}

			&:hover {
				& > svg {
					transform: translate(-2px);
				}
			}
		}
	}

	&-details {
		&__name {
			@include bases.base-heading;
			margin: calc(var(--p-rhythm) * 2) 0 var(--p-rhythm-sm);
			font-size: 74px;
		}

		&__title {
			margin-bottom: calc(var(--p-rhythm) * 0.75);
			font-size: var(--fs-body-sm);
			font-weight: 700;
			text-transform: uppercase;
		}

		&__bio {
			font-size: var(--fs-body);
			max-width: 650px;
		}
	}
}

@include mixins.from_md {
	.person-profile {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: calc(var(--p-rhythm) * 3);

		&-image__back {
			display: none;
		}
	}
}

@include mixins.to_md {
	.person-profile {
		top: var(--p-rhythm-lg);
		grid-template-rows: auto 1fr;
		max-width: calc(var(--page-width) - var(--p-rhythm-lg));
		left: 50%;
		transform: translateX(-50%);

		&-image {
			&__back {
				margin: 0 0 calc(var(--p-rhythm-lg)) calc(var(--p-rhythm) * -1);
				gap: var(--p-rhythm);
			}

			&__image {
				width: calc(var(--p-rhythm-lg) * 6);
				height: calc(var(--p-rhythm-lg) * 6);
				margin: 0 auto;
			}
		}

		&-details {
			&__back {
				display: none;
			}

			&__name {
				font-size: 64px;
				margin-bottom: calc(var(--p-rhythm) * 0.75);
			}

			&__title {
				margin-bottom: calc(var(--p-rhythm) * 1.75);
			}
		}
	}
}
