@use "mixins";
@use 'global/bases';

.person {
	position: relative;
	cursor: pointer;

	&__image {
		@include mixins.round-accent-border;
		padding-top: 100%;
	}

	&__splash {
		pointer-events: none;
		position: absolute;
		margin-top: 30px;
		top: 60%;
		left: calc(100% + var(--p-rhythm));
		opacity: 0;
		z-index: 10;
		transition-property: all;
		transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
		transition-duration: 0.3s;
		transition-delay: 0s;

		&-position {
			@include bases.base-subheading;
			font-weight: 700;
			text-transform: uppercase;
		}

		&-name {
			@include bases.heading-font;
			font-size: 160px;
			letter-spacing: 0px;
			line-height: calc(var(--p-rhythm-lg) * 2.4);
		}
	}

	&:hover &__splash {
		opacity: 1;
		margin-top: 0;
		transition-delay: 0.2s;
	}

	&__profile {
		display: none;
	}
}

@include mixins.to_md {
	.person {
		&__splash {
			display: none !important;
		}
	}
}
