@use 'mixins';
@use 'global/bases';

.l-navigation {
	&-menu {
		font-size: 13px;

		&__item {
			position: relative;
			display: block;
			line-height: 1;
			text-transform: uppercase;

			&-link {
				width: auto;
			}

			&:after {
				display: block;
				content: "";
			}

			&:hover:after {
				position: absolute;
				bottom: -5px;
				height: 1px;
				width: 100%;
				background: var(--t-accent-border-color);
			}
		}
	}
}

@include mixins.to-md {
	.l-navigation {
		transition: transform 400ms ease-in-out;
		height: 100vh;
		width: 100%;
		padding: var(--page-gutter);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-end;
		transform: translateX(100%);
		overflow-y: scroll;
		background: var(--t-page-bg-color);

		&.is-active {
			transform: translateX(0);
		}

		&__logo {
			position: absolute;
			width: 40px;
		}

		&-menu {
			$m: &;

			font-size: 27px;
			margin: 100px 0;
			letter-spacing: 3px;
			display: flex;
			flex-direction: column;
			gap: var(--p-rhythm);
			align-items: flex-start;

			&--submenu {
				font-size: 20px;
				margin: 0;

				#{$m}__item + #{$m}__item {
					margin-top: calc(var(--p-rhythm) / 2);
				}
			}

			.orientation-changing & {
				transition: none;
			}
		}
	}
}

@include mixins.from-md {
	.l-navigation {
		&__logo {
			display: none;
		}

		&-menu {
			font-weight: 700;

			&--submenu {
				font-weight: 500;
				margin-bottom: var(--p-rhythm-sm);
			}
		}
	}

	.l-navigation-menu {
		flex: 1;

		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;

		&__item {
			position: relative;
			margin: 0;

			.submenu {
				transition: opacity 200ms ease-in-out;
				position: absolute;
				top: 100%;
				left: 0;
				min-width: 18ch;

				opacity: 0;
				pointer-events: none;
				&:hover,
				&:active,
				&:focus {
					.submenu {
						opacity: 1;
						pointer-events: all;
					}
				}
			}

			&:hover,
			&:active,
			&:focus {
				.submenu {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&__item + &__item {
			margin-left: var(--p-rhythm);
		}
	}
}
