@use "constants";
@use "mixins";

.l-site-header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 100;

	max-width: var(--page-width);
	margin: 0 auto;
	margin-top: var(--page-gutter-top);

	&__logo {
		display: block;
		width: 32px;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__menu-trigger {
		// position: absolute;
		// top: 0;
		// right: 0;
		z-index: 3;
	}

	.l-navigation {
		z-index: 2;
	}
}

@include mixins.to_md {
	.l-site-header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@include mixins.from-md {
	.l-site-header {
		display: flex;
		justify-content: space-between;

		&__logo {
			width: 50px;
		}

		&__menu-trigger {
			display: none;
		}
	}
}
