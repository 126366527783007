@use "mixins";

.l-artwork-blocks {
	max-width: var(--page-width);
	margin: 0 auto;

	&__title {
		font-size: var(--fs-title);
	}

	> * + * {
		margin-top: calc(var(--p-rhythm) * 2);
	}
}

@include mixins.from_md {
	.l-artwork-blocks {
		> * + * {
			margin-top: 200px;
		}
	}
}
