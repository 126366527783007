@use 'global/bases';
@use "mixins";

.p-home-page {
	.l-site-header {
		&__logo {
			visibility: hidden;
		}
	}
}

body:not(.p-home-page) main {
	padding-top: calc(var(--p-rhythm) * 5);
	background-image: url("../images/header-strip.jpg");
	background-position-x: 50%;
	background-position-y: top;
	background-repeat: no-repeat;
	background-size: 100% calc(var(--p-rhythm) * 5);

	.l-people-block {
		margin: calc(var(--p-rhythm-lg) * 3) auto 0;
	}

	article {
		> div:first-child {
			margin-top: 0;
		}

		> div:last-child {
			margin-bottom: 0;
		}
	}
}

@include mixins.to_md {
	body.profile-open {
		article > div:not(.l-people-block) {
			@include bases.base-blurred;
		}
	}
}
