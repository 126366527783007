@use "mixins";
@use "global/bases";

.l-contact {
	display: grid;
	grid-template-columns: 1fr;
	row-gap: var(--p-rhythm-lg);

	&--title {
		@include bases.base-heading;
		margin-top: 0;
		margin-bottom: calc(var(--p-rhythm) * 1.5);
	}

	&--form {
		font-size: var(--fs-subheading);
		transition: opacity 150ms ease-in-out;

		&.thinking {
			opacity: 0.5;
			cursor: wait;
			pointer-events: none;
		}

		&-input {
			position: relative;

			&-control {
				height: var(--form-control-height);
				width: 100%;
				padding: var(--form-control-padding);
				padding-right: 7ch;
				background: none;
				border: 1px solid var(--t-accent-border-color);
				outline: none;
				color: var(--t-text-color);
				font-size: var(--fs-control);
				font-weight: 700;

				&:active,
				&:focus {
					appearance: none;
					border-color: var(--t-accent-color);
				}

				&::placeholder {
					color: var(--t-dark-text-color);
				}
			}

			&-submit {
				position: absolute;
				right: var(--form-control-padding);
				top: 50%;
				transform: translateY(-50%);
				width: auto;
			}
		}

		&-message {
			opacity: 0;
			transition: 150ms ease-in;
			margin-top: 1em;

			&.show {
				opacity: 1;
			}
		}
	}
}

@include mixins.from-md {
	.l-contact {
		grid-template-columns: auto var(--content-width);
		column-gap: var(--p-rhythm-lg);

		&--title {
			margin-bottom: var(--p-rhythm);
		}

		&--visit {
			display: flex;
			justify-content: flex-end;

			.site-link {
				max-width: 450px;

				&--inner {
					line-height: 1.2;
					padding: 0;
				}
			}
		}

		&--visit {
			display: flex;
			justify-content: flex-end;

			.site-link {
				max-width: 450px;

				&--inner {
					line-height: 1.2;
					padding: 0;
				}
			}
		}

		&--form {
			width: 100%;
		}
	}
}
