@use "mixins";

.l-site-footer {
	position: relative;
	padding: calc(var(--p-rhythm-lg) * 4) 0 var(--p-rhythm-sm);
	background-repeat: no-repeat;
	background-position-y: 100%;
	background-size: calc(var(--p-rhythm) * 54) calc(var(--p-rhythm) * 6);
	background-image: url("../images/footer-pattern.png");

	&--inner {
		max-width: var(--page-width);
		margin: 0 auto;
	}

	&__lower {
		margin: calc(var(--p-rhythm) * 3) 0 0;

		&-acknowledgement {
			margin: 0 0 calc(var(--p-rhythm-lg) * 4);
		}

		&-bar {
			display: flex;
			font-weight: 700;
			font-size: var(--fs-body-med);
			color: var(--t-footer-text-color);

			&--copyright {
				display: none;
			}
		}
	}
}

@include mixins.from_md {
	.l-site-footer {
		padding: 260px 0 calc(var(--p-rhythm-sm));
		background-size: calc(var(--p-rhythm) * 54) calc(var(--p-rhythm) * 6);
		background-position-y: bottom;

		&__lower {
			margin: 470px 0 0;

			&-acknowledgement {
				max-width: 66%;
				margin: 0 0 calc(var(--p-rhythm-lg) * 1.5);
			}

			&-bar {
				> *:not(:first-child) {
					margin-left: var(--p-rhythm);
				}

				&--copyright {
					display: block;
					height: fit-content;
					margin: auto 0;
				}
			}
		}
	}
}
