@use 'mixins';
@use 'global/bases';

.l-text_block {
	max-width: var(--page-width);
	margin: calc(var(--p-rhythm-lg) * 3) auto;

	&__content {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: var(--p-rhythm);
	}
}

@include mixins.from-md {
	.l-text_block {
		margin: 400px auto calc(var(--p-rhythm-lg) * 3);

		&__content {
			grid-template-columns: auto var(--content-width);
			column-gap: var(--p-rhythm-lg);

			&-title.subtitle {
				@include bases.base-subheading;
				font-size: var(--fs-medtitle);
				font-weight: 500;
				text-transform: uppercase;
			}
		}
	}
}
