@use 'global/bases';

.l-sponsors {
	max-width: var(--page-width);
	margin: var(--block-spacing) auto 0;

	&__title {
		@include bases.base-heading;
		margin-bottom: var(--p-rhythm);
	}
}
