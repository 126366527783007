@use 'constants';
@use 'mixins';
@use 'global/bases';

.l-home-fold {
	position: relative;
	height: 85vh;

	&__content {
		display: grid;
		align-items: end;
		position: relative;
		height: calc(100% - 110px);
		max-width: var(--page-width);
		margin: 0 auto;

		&-monogram {
			width: 19vw;
			max-width: calc(var(--p-rhythm-lg) * 5);
		}

		&-body {
			grid-column-start: 1;
			grid-row-start: 1;
		}
	}

	&__tagline {
		@include bases.heading-font;
		font-size: var(--fs-big-body);
		margin-bottom: 1em;
	}

	&__body {
		margin-bottom: calc(var(--p-rhythm) * 1.3);
	}

	&__bg {
		& > img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: -1;
		}
	}
}

@include mixins.to_md {
	.l-home-fold__content-monogram {
		position: absolute;
		top: var(--page-gutter);
		left: 0;
	}
}

@include mixins.from-md {
	.l-home-fold {
		height: 88vh;

		&__content {
			grid-template-columns: auto 490px;
			height: 100%;
			padding: 0 calc(var(--p-rhythm-lg) * 2) calc(var(--p-rhythm-lg) * 2) 0;

			&-monogram {
				display: block;
				grid-column-start: 1;
				grid-row-start: 2;
			}

			&-body {
				grid-column-start: 2;
				grid-row-start: 2;
			}
		}

		&__body {
			margin-bottom: var(--p-rhythm-lg);
		}
	}
}
