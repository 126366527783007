@use "mixins";

.l-award-fold {
	// Very similar to title-rich-text
	max-width: var(--page-width);
	margin: 0 auto calc(var(--p-rhythm-lg) * 3);

	&__content {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: var(--p-rhythm);
		justify-items: start;

		&-body {
			.btn {
				margin-top: var(--p-rhythm);
			}
		}
	}
}

@include mixins.from-md {
	.l-award-fold {
		&__content {
			grid-template-columns: auto var(--content-width);
			column-gap: var(--p-rhythm-lg);
		}
	}
}
