@use "mixins";

.read-more {
	display: flex;
	align-items: center;

	$r: &;

	&__bar {
		flex: 1;
		height: 2px;
		margin-top: 1px;
		background: #25200e;
		background-image: linear-gradient(
			to right,
			#25200e 0%,
			#544416 15%,
			#25200e 30%,
			#25200e 100%
		);
		background-repeat: no-repeat;
		background-size: 400px 2px;
		background-position-x: -100px;
		display: inline-block;
		position: relative;
		max-width: calc(var(--p-rhythm-lg) * 3);
	}

	&__text {
		margin-left: calc(var(--p-rhythm-sm) * 0.75);
		font-size: var(--fs-body-sm);
		font-weight: 700;
		letter-spacing: 1.333333;
	}

	&:hover {
		cursor: pointer;

		#{$r}__bar {
			@include mixins.shimmer-effect;
		}
	}
}
