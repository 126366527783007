@use "mixins";
$dropdown-border-radius: 21px !default;

.dropdown {
	$d: &;

	position: relative;
	min-width: 250px;
	width: auto;
	transition: width 150ms ease-in-out;
	text-transform: uppercase;

	&__trigger {
		position: relative;
		width: 100%;
		height: 2.625rem;
		transition: 0.2s ease-in-out;
		border-radius: #{$dropdown-border-radius};
		border: 1px solid var(--t-accent-border-color);
		background: transparent;
		color: var(--t-text-color);
		text-transform: uppercase;
		font-variant-numeric: lining-nums;
		display: flex;
		align-items: center;
		cursor: pointer;

		&[data-disabled] {
			cursor: not-allowed;
			color: var(--t-dark-text-color);
			border-color: var(--t-dark-text-color);

			svg {
				stroke: var(--t-dark-text-color);
			}
		}

		svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: var(--p-rhythm-sm);
			width: 12px;
			stroke: var(--t-accent-color);
			transition: 0.3s all;
		}
	}

	&__selected-text {
		padding: 0 calc(var(--p-rhythm-sm) + 18px) 2px var(--p-rhythm-sm);
		white-space: nowrap;
	}

	&__search {
		border-bottom: 1px solid var(--t-accent-border-color);
		& > input {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			appearance: none;
			background: transparent;
			color: var(--t-text-color);
			text-transform: uppercase;
			font-variant-numeric: lining-nums;
			&::placeholder {
				color: var(--c-text-color);
			}
		}

		& > svg {
			display: block;
			width: auto;
			height: 1em;
		}
	}

	&__values-list {
		position: absolute;
		top: calc(var(--p-rhythm-sm) * 0.5 + 2.625rem);
		left: 0;
		width: 100%;
		list-style: none;
		overflow: hidden;
		max-height: 0;
		transition: 0.3s ease-in-out;
		z-index: 100;
		padding: 0 var(--p-rhythm-sm);
		border-radius: #{$dropdown-border-radius};
		background: var(--t-page-bg-color);
		opacity: 0;

		li {
			display: flex;
			padding: 1px 0;
			align-items: center;
			position: relative;
			cursor: pointer;
			transition: background-color 0.3s;
			opacity: 1;
			line-height: 1;

			&:hover {
				text-decoration: underline;
			}

			& + li {
				margin-top: 0.4em;
			}

			&.hidden {
				max-height: 0;
				overflow: hidden;
				padding: 0;
				opacity: 0;
				margin: 0;
			}
		}
	}

	&.open {
		#{$d}__trigger {
			#{$d}__arrow {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		#{$d}__values-list {
			padding: var(--p-rhythm-sm);
			max-height: 320px;
			overflow: auto;
			border: 1px solid var(--t-accent-border-color);
			opacity: 1;
		}
	}

	@include mixins.to-md {
		&__clear {
			display: none;
		}
	}

	@include mixins.from-md {
		&__arrow {
			&--mobile {
				display: none;
			}
		}
	}
}
