@use "mixins";

.l-form-controls {
	max-width: var(--page-width);
	margin: var(--p-rhythm) auto 0;

	&__filter {
		&-topbar {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			&-logo {
				width: 40px;
			}

			&-buttons {
				display: flex;

				div {
					padding: 0 10px;

					&:last-child {
						padding-right: 0;
					}
				}

				&--clear:not(.active) {
					color: var(--t-dark-text-color);
				}
			}
		}

		&-dropdowns {
			display: flex;
			flex-direction: column;
			margin: calc(var(--p-rhythm) * 3) 0 0;
		}

		&-submit {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			bottom: 0;
			left: 0;
			width: calc(100% - (var(--page-gutter) * 2));
			height: var(--p-rhythm-lg);
			margin: 0 var(--page-gutter) var(--page-gutter);
			border-radius: 21px;
			border: 1px solid var(--t-accent-border-color);
		}
	}

	&--clear-btn {
		cursor: pointer;

		&:not(.active) {
			color: var(--t-dark-text-color);
			cursor: default;
		}
	}
}

@include mixins.to_md {
	.l-form-controls {
		&__filter {
			position: absolute;
			left: 100%;
			top: 0;
			width: 100%;
			height: 100vh;
			padding: var(--page-gutter);
			z-index: 101;
			background: var(--t-page-bg-color);
			transition: 0.3s all;

			&.is-active {
				left: 0;
			}

			&-dropdowns {
				.dropdown {
					max-width: initial;

					&__trigger {
						border: 0;
						height: initial;
						border-radius: 0;
						padding: 0;
						font-size: 27px;
						font-weight: 700;
						letter-spacing: 3px;
					}

					&__values-list {
						border: 0;
						padding: 0;
						font-size: 20px;

						&.open {
							padding: var(--p-rhythm) 0 calc(var(--p-rhythm-lg));
						}
					}

					&:not(:first-child) {
						margin: var(--p-rhythm) 0 0;
					}
				}
			}
		}

		&--clear-btn {
			display: none;
		}

		.dropdown__values-list {
			position: initial;
		}
	}
}

@include mixins.from_md {
	.l-form-controls {
		&__mobile-button {
			display: none;
		}

		&__filter {
			&-topbar,
			&-submit {
				display: none;
			}

			&-dropdowns {
				flex-direction: row;
				flex-wrap: wrap;
				margin: var(--p-rhythm-lg) 0 0;
				gap: calc(var(--p-rhythm) * 0.75);
				align-items: center;
			}
		}
	}
}
