@use "global/bases";
@use "mixins";

.award-animation {
	$aa: &;

	&.show-text {
		#{$aa}__content {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&__stage {
		&-header {
			&-small {
				@include bases.base-subheading;
				margin-bottom: var(--p-rhythm-sm);
				font-weight: 700;
				grid-area: h1;
			}

			&-big {
				@include bases.base-heading;
				&:nth-child(2) {
					grid-area: h2;
				}
				&:nth-child(3) {
					grid-area: h3;
				}
			}
		}

		&-inner {
			height: 100%;
			width: 100%;
		}
	}

	&__content {
		max-width: var(--page-width);

		&-body {
			margin: calc(var(--p-rhythm) * 1.2) 0 0;
			max-width: var(--content-width-sm);
			font-size: 20px;
		}

		.read-more {
			margin-top: var(--p-rhythm);
			font-size: 12px;
		}
	}

	&__mobile-header-award {
		grid-area: a;
		position: relative;

		& > img {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 135px;
		}
	}
}

@include mixins.to-md {
	.award-animation {
		margin: 0 0 155px;
		max-width: 100vw;

		&__stage {
			overflow-x: hidden;
			position: relative;
		}

		&__stage-header {
			display: grid;

			grid-template-areas:
				"h1 a"
				"h2 a"
				"h3 a";
		}

		&__content {
			margin: 380px auto 0 auto;
		}

		canvas {
			max-width: 100%;
		}
	}
}

@include mixins.from-md {
	.award-animation {
		height: 2000px;
		margin-bottom: 100vh;
		padding-top: var(--p-rhythm-lg);
		box-sizing: content-box;

		&__stage {
			position: sticky;
			top: var(--p-rhythm-lg);
			padding-top: 33.333%;

			&-inner {
				position: absolute;
				top: 0;
				padding-bottom: calc(var(--p-rhythm-lg) * 4);
			}

			&-header {
				&-small {
					font-size: 30px;
				}

				&-big {
					font-size: 83px;
				}
			}
		}

		&__content {
			width: fit-content;
			transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
			transform: translateY(20px);
			opacity: 0;
			position: absolute;
			margin: 0 auto 0 calc(50% + 40px - 425px);

			&-body {
				margin: calc(var(--p-rhythm-lg) * 1.2) 0 0;
			}

			.read-more {
				margin-top: var(--p-rhythm-lg);
			}
		}

		&__mobile-header-award {
			display: none;
		}
	}
}
