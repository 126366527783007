@use 'mixins';
@use 'global/bases';

.l-people-block {
	max-width: var(--page-width);
	margin: var(--block-spacing) auto 0;

	&__subtitle {
		@include bases.base-heading;
		margin-bottom: var(--p-rhythm-sm);
	}

	&__title {
		@include bases.base-subheading;

		&:first-child {
			font-size: var(--fs-medtitle);
			font-weight: 500;
		}
	}

	&__grid {
		position: relative;
		padding-top: calc(var(--p-rhythm) * 2);

		&-people {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: var(--p-rhythm);

			row-gap: var(--p-rhythm);
			column-gap: calc(var(--p-rhythm) * 2);

			&.person-open .person {
				@include bases.base-blurred;
			}
		}
	}
}

@include mixins.from-md() {
	.l-people-block {
		&__grid {
			&-people {
				grid-gap: calc(var(--p-rhythm) * 2);

				.person {
					transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
					will-change: opacity;
					opacity: 1;
					z-index: 1;
				}

				&--5 {
					grid-template-columns: repeat(5, 1fr);

					.person {
						&:nth-child(5n + 4) .person__splash,
						&:nth-child(5n + 5) .person__splash {
							left: 0;
							transform: translateX(-100%);
						}

						&:nth-child(1n + 11) .person__splash {
							top: -50%;
						}
					}
				}

				&--4 {
					grid-template-columns: repeat(4, 1fr);

					.person {
						&:nth-child(4n + 3) .person__splash,
						&:nth-child(4n + 4) .person__splash {
							left: 0;
							transform: translateX(-100%);
						}

						&:nth-child(1n + 9) .person__splash {
							top: -50%;
						}
					}
				}

				&.hovered {
					.person {
						opacity: 0.1;

						&:hover {
							opacity: 1;
							z-index: 10;
						}
					}
				}
			}
		}
	}
}
