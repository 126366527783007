@use "mixins";
@use "global/bases";

.titled-richtext {
	$rt: &;

	&__content {
		display: grid;
		grid-template-columns: 1fr;
		row-gap: var(--p-rhythm);
	}

	&:not(:first-child) {
		#{$rt}__content {
			&-title {
				@include bases.base-medheading;
			}
		}
	}
}

@include mixins.from-md {
	.titled-richtext {
		&__content {
			grid-template-columns: auto var(--content-width);
			column-gap: var(--p-rhythm-lg);
		}
	}
}
