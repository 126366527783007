@use 'mixins';

.l-categories-block {
	max-width: var(--page-width);
	margin: 0 auto 0;

	&__title {
		margin-bottom: var(--p-rhythm-lg);
	}
}

.subcategories-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1px;
	background-color: var(--t-accent-border-color);
	border: 1px solid var(--t-accent-border-color);
	border-left: none;
	border-right: none;

	@include mixins.from-md {
		grid-template-columns: repeat(4, 1fr);
	}
}
