.built-by {
	display: flex;
	align-items: center;
	width: unset;

	img {
		width: 45px;
		margin-left: 15px;
	}
}
