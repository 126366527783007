@use "constants";
@use "mixins";

@function strip-units($number) {
	// Strips units from string e.g. 15px -> 15
	@return $number / ($number * 0 + 1);
}

@mixin gen-clamp(
	$propName,
	$minFontSizePx,
	$maxFontSizePx,
	$minWidthPx: constants.$bp-tiny,
	$maxWidthPx: constants.$bp-very-large
) {
	$minWidth: strip-units($minWidthPx) / 16;
	$maxWidth: strip-units($maxWidthPx) / 16;
	$minFontSize: strip-units($minFontSizePx) / 16;
	$maxFontSize: strip-units($maxFontSizePx) / 16;

	$slope: ($maxFontSize - $minFontSize) / ($maxWidth - $minWidth);
	$yAxisIntersection: -$minWidth * $slope + $minFontSize;

	#{$propName}: clamp(
		#{$minFontSize * 1rem},
		#{($yAxisIntersection * 1rem)} + " + " + #{($slope * 100vw)},
		#{$maxFontSize * 1rem}
	);

	// If these varaible names change, update them in updateClampedCSS.js
	@at-root {
		:export {
			#{$propName}_min-width: #{strip-units($minWidthPx)};
			#{$propName}_max-width: #{strip-units($maxWidthPx)};
			#{$propName}_min-fs: #{strip-units($minFontSizePx)};
			#{$propName}_max-fs: #{strip-units($maxFontSizePx)};
		}
	}
}

:root {
	--t-light-text-color: #fff;
	--t-text-color: #c5c5c5;
	--t-dark-text-color: #757575;
	--t-footer-text-color: #666666;
	--t-category-dark-text-color: #999999;
	--t-accent-color: #d4af37;
	--t-accent-border-color: #8a7224;
	--t-page-bg-color: black;

	--p-rhythm: 24px;
	--p-rhythm-sm: calc(var(--p-rhythm) / 2);
	--p-rhythm-lg: calc(var(--p-rhythm) * 2);
	--page-gutter: min(5vw, 75px);
	--page-gutter-top: min(5vw, calc(var(--p-rhythm-sm) * 1.5));
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);
	--page-width-lg: min(100vw, 1920px);
	--block-spacing: calc(var(--p-rhythm-lg) * 7);
	--content-width: min(55vw, 960px);
	--content-width-sm: 630px;

	--fw-body: 500;
	--fs-body-sm: 12px;
	--fs-body-med: 15px;

	@include gen-clamp("--fs-control", 15px, 25px);
	@include gen-clamp("--fs-body", 15px, 18px);
	@include gen-clamp("--fs-big-body", 20px, 24px);
	@include gen-clamp("--fs-title", 42px, 83px);
	// TODO: Confirm this is a new font size and not redeisgn of a previous
	@include gen-clamp("--fs-medtitle", 30px, 50px);
	@include gen-clamp("--fs-subtitle", 15px, 30px);

	--form-control-height: 30px;
	--form-control-padding: calc(var(--p-rhythm-sm) * 0.75);

	@include mixins.from-md() {
		--form-control-height: var(--p-rhythm-lg);
		--p-rhythm: 50px;
		--p-rhythm-lg: 70px;
	}
}
