.menu-icon {
	display: inline-block;
	position: relative;
	width: auto;
	font-size: var(--fs-body-sm);
	font-weight: 700;

	& > .open,
	& > .close {
		position: absolute;
		right: 0;
		line-height: 1;
		transition: all 150ms ease-in-out;
	}

	& > .close {
		opacity: 0;
		transform: translateY(1em);
	}

	&.is-active {
		& > .open {
			opacity: 0;
			transform: translateY(-1em);
		}

		& > .close {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
