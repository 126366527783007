@use 'global/bases';
@use 'mixins';

.l-finalists-block {
	max-width: var(--page-width-lg);
	margin: calc(var(--p-rhythm)) auto 0;

	&__titlebar {
		max-width: var(--page-width);
		margin: 0 auto;

		&-subtitle {
			@include bases.base-heading;
			margin-bottom: var(--p-rhythm-sm);
		}

		&-title {
			@include bases.base-subheading;

			&:first-child {
				font-size: var(--fs-medtitle);
				font-weight: 500;
			}
		}
	}

	&__grid {
		position: relative;
		padding-top: calc(var(--p-rhythm) * 1.4);

		&-finalists {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			&--noresults {
				width: var(--page-width);
				margin: var(--p-rhythm);
			}
		}
	}
}

@include mixins.from-md() {
	.l-finalists-block {
		&__grid {
			&-finalists {
				grid-template-columns: repeat(5, 1fr);

				.finalist {
					transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
					will-change: opacity;
					opacity: 1;
					z-index: 1;

					&:nth-child(5n + 5) .finalist__profile,
					&:nth-child(5n + 4) .finalist__profile {
						text-align: right;
						left: calc(0% - var(--p-rhythm));
						transform: translate(-100%, -50%);
					}
				}

				&:hover {
					.finalist {
						opacity: 0.1;

						&:hover {
							opacity: 1;
							z-index: 10;
						}
					}
				}

				&.finalist-open .finalist {
					@include bases.base-blurred;
				}
			}
		}
	}
}
