@use "global/bases";

.sponsor-category {
	&__title {
		@include bases.base-subheading;
		margin-bottom: calc(var(--p-rhythm) * 2);
	}

	&__sponsor-grid {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: calc(var(--p-rhythm) * 2);
	}

	&:not(:last-child) {
		margin-bottom: calc(var(--p-rhythm) * 5);
	}
}
