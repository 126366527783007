@use "mixins";

.category {
	background-color: var(--t-page-bg-color);
	padding: var(--p-rhythm-sm) var(--p-rhythm);

	&__title {
		color: var(--t-light-text-color);
		font-weight: 700;
		font-size: 20px;
		margin-bottom: calc(var(--p-rhythm-sm) * 0.25);
	}

	&__description {
		font-size: 1rem;
		color: var(--t-category-dark-text-color);
		line-height: 1.125;
	}

	&__desktop-empty {
		display: none;
	}
}

@include mixins.from_md {
	.category {
		padding: var(--p-rhythm-sm);

		&:nth-child(4n + 1) {
			padding-left: var(--p-rhythm);
		}

		&:nth-child(4n + 4) {
			padding-right: var(--p-rhythm);
		}

		&__desktop-empty {
			display: block;
		}

		&__mobile-empty {
			display: none;
		}
	}
}
