.grand-diemen {
	display: flex;

	&__award {
		margin-right: var(--p-rhythm-sm);

		img {
			height: 50px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-top: 3px;

		&-category {
			font-size: var(--fs-body-sm);
			color: var(--t-dark-text-color);
		}

		&-title {
			display: inline;
			font-size: 30px;
			font-weight: 700;
			line-height: 0.8;
		}
	}
}
