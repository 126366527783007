@use "global/bases";
@use "mixins";

.social-bar {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	gap: var(--p-rhythm-sm);

	&__item {
		height: var(--form-control-height);
	}

	&__link {
		@include bases.heading-font;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding: 0 calc(var(--p-rhythm));
		border: 1px solid var(--t-accent-border-color);
		font-weight: 700;
		font-size: var(--fs-control);
	}

	@include mixins.to_md {
		&__item {
			width: calc((100% - var(--p-rhythm-sm)) / 2);
		}
	}

	@include mixins.from_md {
		&__link {
			padding: 0 calc(var(--p-rhythm-sm) * 0.75);
		}
	}
}
