@use "mixins";
@use 'global/bases';

.finalist {
	position: relative;
	cursor: pointer;

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__profile {
		pointer-events: none;
		position: absolute;
		top: 50%;
		left: calc(100% + var(--p-rhythm));
		transform: translateY(-50%);
		z-index: 10;
		min-width: fit-content;
		max-width: 150%;
		width: max-content;

		&-title {
			@include bases.base-heading;
			font-size: var(--fs-medtitle);
			margin: 0 0 var(--p-rhythm-sm);
		}

		&-organisation {
			font-size: var(--fs-subtitle);
			margin: 0 0 calc(var(--p-rhythm-sm) * 0.5);
		}

		&-awards {
			font-size: 16px;
			letter-spacing: 0;
			font-weight: 600;

			&--title {
				text-transform: uppercase;
				color: var(--t-accent-color);
				margin: calc(var(--p-rhythm-sm) * 0.75) 0 calc(var(--p-rhythm-sm) * 0.5);
			}

			.award {
				&--category {
					color: var(--t-dark-text-color);
					text-transform: uppercase;
					margin: 0 0 calc(var(--p-rhythm-sm) * 0.125);
				}

				&--award {
					margin: 0 0 calc(var(--p-rhythm-sm) * 0.5);
				}
			}
		}
	}

	&:not(:hover) &__profile {
		display: none;
	}
}

@include mixins.to_md {
	.finalist {
		&__profile {
			display: none;
		}
	}
}
